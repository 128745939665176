/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer } from "react";
import { parseString } from "xml2js";
import AppContext from "./appContext";
import appReducer from "./appReducer";

import { useMsal } from "@azure/msal-react";
import {
  loginRequest,
  storageRequest,
  dbRequest,
  protectedRequest,
  studentFileAccount,
  mainStorageAccount,
} from "../../authConfig";

import {
  CHANGE_STATE,
  LOGIN_SUCCESS,
  CHECK_CLASSES,
  CHECK_STAFF_TYPE,
} from "./types";

import { serverUrl, storageEndpoint, studentEndpoint } from "../../authConfig";
import axios from "axios";
import { useHistory } from "react-router-dom";

import axiosRetry from "axios-retry";
// axiosRetry(axios,{retries:3})

export const AppState = (props) => {
  const history = useHistory();
  // const history = useHistory();

  const initialState = {
    userInfo: "",
    privileges: null,
    schoolInfo: {},
    authorised: true,
    dbUser: "",
    userClasses: [],
    classesChecked: false,
    editMode: false,
    staffTypeChecked: false,
    colours: [
      "#c3e3cc",
      "#e3ccc3",
      "#e3c3d3",
      "#c6c3e3",
      "#c3d7e3",
      "#cc7b78",
      "#ab86c2",
      "#abb8ab",
      "white",
      "#f0c690",
      "#f0c690",
      "#afdde3",
    ],
    subscriptionLevel: 2,
  };

  const [state, dispatch] = useReducer(appReducer, initialState);
  const { instance, accounts } = useMsal();

  //  const recordSignin = async (preferredName,emailAddress) => {
  //    await dbPost({
  //      type: "recordSignIn",
  //      SignedInUser: `${preferredName} (${emailAddress})`,
  //      DayDate: moment().format("ll"),
  //      DayTime: moment().format("dddd, MMMM Do YYYY, h:mm:ss a"),
  //    });
  //  };

  const RequestAccessToken = async (resourceType) => {
    const requestType = () => {
      switch (resourceType) {
        case "graph":
          return loginRequest;

        case "storage":
          return storageRequest;

        case "db":
          return dbRequest;
        case "protected":
          return protectedRequest;

        default:
          break;
      }
    };

    // console.log("RequestAccessToken called");
    const request = {
      ...requestType(),
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    const userInfo = await instance
      .acquireTokenSilent(request)
      .then((response) => {
        // console.log(`${resourceType} token requested`, response);
        if (resourceType === "graph") {
          const userInfo = {
            idToken: response.idToken,
            preferredName: response.account.name,
            emailAddress: response.account.username,
            userGroups: response.idTokenClaims.groups,
          };

          // recordSignin(userInfo.preferredName,userInfo.emailAddress)

          dispatch({
            type: LOGIN_SUCCESS,
            payload: userInfo,
          });
        }

        return response;
      })

      .catch((e) => {
        console.log(e);
        instance.acquireTokenRedirect(request).then((response) => {});
      });

    return userInfo;
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const getHistoryPdf = async (toDate, fromDate, studentId) => {
    const tokenResponse = await RequestAccessToken("protected");

    const accessToken = tokenResponse.accessToken;

    // console.log(accessToken)

    const url = `${serverUrl}/getHistoryAsPdf`;

    const res = await axios.post(
      url,
      {
        toDate: toDate,
        fromDate: fromDate,
        studentId: studentId,
        isClientDev: process.env.NODE_ENV === "development" ? true : false,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (res.data === "no history") {
      alert("No updates in selected time period");
    } else {
      const contentType = "application/pdf";
      const b64Data = res.data;

      const blob = b64toBlob(b64Data, contentType);
      const url2 = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url2;
      link.setAttribute("download", `${studentId}_HISTORY`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }

    // var a = document.createElement("a"); //Create <a>
    // a.href = "data:application/pdf;base64," + res.data; //Image Base64 Goes here
    // a.download = `STUDENT_HISTORY_${studentId}.pdf`; //File name Here
    // a.click(); //Downloaded file
  };

  const listHistoryBlobs = async () => {
    const tokenResponse = await RequestAccessToken("storage");

    const accessToken = tokenResponse.accessToken;

    const url = `${studentEndpoint}/history?restype=container&comp=list`;

    var d = new Date();

    const res = await axios.get(url, {
      params: {
        maxresults: 200,
        include: "metadata",
        isClientDev: process.env.NODE_ENV === "development" && "dev",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "x-ms-version": "2017-11-09",
        "x-ms-date": d.toUTCString(),
      },
    });

    const json = new Promise((resolve, reject) => {
      parseString(res.data, (err, result) => {
        resolve(result);
      });
    });

    return json;
  };

  const accessProtectedRoute = async () => {
    const tokenResponse = await RequestAccessToken("protected");

    console.log(tokenResponse);

    const accessToken = tokenResponse.accessToken;

    const url = `${serverUrl}/protected`;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        isClientDev: process.env.NODE_ENV === "development"&& "dev",
      },
    });

    console.log(res);
  };

  const createPdf = async () => {
    try {
      const tokenResponse = await RequestAccessToken("graph");
      const url = `${serverUrl}/createPdf`;
      const res = await axios.post(
        url,
        { isClientDev: process.env.NODE_ENV === "development" ? true : false },
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.idToken}`,
          },
          responseType: "blob",
        }
      );
      console.log("response is", res);

      const url2 = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url2;
      link.setAttribute("download", "test.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log(err);
      history.push("/error");
    }
  };

  const createExcel = async (dataSet, groupBy, isExam) => {
    try {
      const tokenResponse = await RequestAccessToken("protected");

      console.log("dataset", dataSet);
      console.log("token response", tokenResponse);

      const url = `${serverUrl}/createExcel`;
      const res = await axios.post(
        url,
        {
          dataSet: dataSet,
          groupBy: groupBy,
          isExam: isExam,
          isClientDev: process.env.NODE_ENV === "development" ? true : false,
        },
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.accessToken}`,
          },
          responseType: "blob",
        }
      );
      console.log("response is", res);

      const url2 = window.URL.createObjectURL(
        new Blob([res.data], {
          type: res.data.type,
        })
      );
      const link = document.createElement("a");
      link.href = url2;
      link.setAttribute("download", "AARA Students.xlsx"); //or any other extension
      document.body.appendChild(link);

      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      console.log(err);
      history.push("/error");
    }
  };

  const dbGet = async (params) => {
    try {
      // const tokenResponse = await RequestAccessToken("db");

      const tokenResponse = await RequestAccessToken("protected");

      const token = tokenResponse.accessToken;

      const res = await axios.get(`${serverUrl}/dbGet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...params,
          isClientDev: process.env.NODE_ENV === "development" && "dev",
        },
      });

      return res.data;
    } catch (err) {
      console.log(err);
      history.push("/error");
      // instance.logoutRedirect({
      //   postLogoutRedirectUri: "/",
      // });
    }
  };

  const dbPost = async (formData) => {
    try {
      const tokenResponse = await RequestAccessToken("protected");

      const token = tokenResponse.accessToken;
      // console.log("the data passd in was", formData);
      // const data = JSON.stringify(formData);

      // console.log("stringified", data);

      const url = `${serverUrl}/dbPost`;

      // const res = await axios(config);

      const res = await axios.post(
        url,
        {
          ...formData,
          isClientDev: process.env.NODE_ENV === "development" ? true : false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return res.data;
    } catch (err) {
      history.push("/error");
      console.log(err);
    }
  };

  const testDb = async (params) => {
    const tokenResponse = await RequestAccessToken("db");

    const token = tokenResponse.accessToken;

    const res = await axios.get(`${serverUrl}/HttpTrigger1`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ...params,
        isClientDev: process.env.NODE_ENV === "development" && "dev",
      },
    });

    console.log(res);

    return res;
  };

  const getPhoto = async (container, id) => {
    const tokenResponse = await RequestAccessToken("storage");

    const token = tokenResponse.accessToken;

    const customRequest = axios.create({
      baseURL: `${storageEndpoint}/${container}/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-ms-version": "2017-11-09",
      },
    });

    axiosRetry(customRequest, { retries: 3 });

    const res = await customRequest.get(`${id}`);

    return res.data;
  };

  const downloadStudentFile = async (container, blobName) => {
    try {
      const tokenResponse = await RequestAccessToken("storage");

      const token = tokenResponse.accessToken;

      const res = await axios.get(
        `${studentEndpoint}/${container}/${blobName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-ms-version": "2017-11-09",
          },
          responseType: "blob",
        }
      );

      // console.log(res.data.type);

      const url = window.URL.createObjectURL(
        new Blob([res.data], {
          type: res.data.type,
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", blobName); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      console.log(err);
      history.push("/error");
    }
  };

  const downloadBlob = async (container, blobName) => {
    try {
      const tokenResponse = await RequestAccessToken("storage");

      const token = tokenResponse.accessToken;

      const res = await axios.get(
        `${storageEndpoint}/${container}/${blobName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "x-ms-version": "2017-11-09",
          },
          responseType: "blob",
        }
      );

      console.log(res.data);

      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.data.type })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", blobName); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      console.log(err);
      history.push("/error");
    }
  };

  const uploadBlobServer = async (fileArray, accountType, container) => {
    console.log("The container is", container);
    const tokenResponse = await RequestAccessToken("protected");

    const accessToken = tokenResponse.accessToken;

    const formData = new FormData();

    console.log("file array in side app state", fileArray);

    for (const file of fileArray) {
      formData.append(file.fileTitle, file.file);
    }
    formData.append("container", container);
    if (accountType === "main") {
      formData.append("storageAccount", mainStorageAccount);
    } else {
      formData.append("storageAccount", studentFileAccount);
    }
    formData.append(
      "isClientDev",
      process.env.NODE_ENV === "development" ? true : false
    );

    // formData.append("fileName", fileName);

    const url = `${serverUrl}/uploadBlob`;

    console.log(formData);

    const res = await axios.post(
      url,
      formData,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return res;
  };

  const uploadBlob = async (file, container, fileType, fileName) => {
    try {
      const tokenResponse = await RequestAccessToken("storage");

      var d = new Date();

      const token = tokenResponse.accessToken;

      const url = `${storageEndpoint}/${container}/${fileName.replace(
        /^a-zA-Z0-9 ]/g,
        ""
      )}`;

      // formData.append('Body',file,fileName)

      const res = await axios.put(url, file, {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-ms-version": "2017-11-09",
          "x-ms-date": d.toUTCString(),
          "x-ms-blob-type": "BlockBlob",
          "Content-type": fileType,
          // "Content-length":fileSize
        },
      });

      console.log(res);
      return res.data;
    } catch (err) {
      console.log(err);
      history.push("/error");
    }
  };

  const getMyClasses = async () => {
    const res = await dbGet({
      type: "getMyClasses",
      staffId: state.dbUser.StaffId,
    });
    dispatch({
      type: CHECK_CLASSES,
      payload: res.map((classId, index) => {
        return {
          ...classId,
          ClassColour: state.colours[index],
          value: classId.ClassId + classId.YearLevel,
          label: classId.ClassId,
        };
      }),
    });
    return res;
  };

  const changeStaffType = (isTeacher, isCaseManager) => {
    dispatch({
      type: CHECK_STAFF_TYPE,
      payload: {
        isTeacher: isTeacher,
        isCaseManager: isCaseManager,
      },
    });
  };

  const changeAppState = (category, value) => {
    dispatch({
      type: CHANGE_STATE,
      payload: {
        category: category,
        value: value,
      },
    });
  };

  const getProtectedToken = async () => {
    const tokenRes = await RequestAccessToken("protected");
    const token = tokenRes.accessToken;

    return token;
  };

  // useEffect(()=>{
  //   if(state.dbUser.StaffId){
  //     getMyClasses()
  //   }
  // },[state.dbUser])

  return (
    <AppContext.Provider
      value={{
        userInfo: state.userInfo,
        privileges: state.privileges,
        schoolInfo: state.schoolInfo,
        authorised: state.authorised,
        dbUser: state.dbUser,
        editMode: state.editMode,
        userClasses: state.userClasses,
        colours: state.colours,
        classesChecked: state.classesChecked,
        staffTypeChecked: state.staffTypeChecked,
        subscriptionLevel: state.subscriptionLevel,
        accessProtectedRoute,
        RequestAccessToken,
        dbGet,
        getPhoto,
        testDb,
        changeAppState,
        dbPost,
        uploadBlob,
        downloadBlob,
        createExcel,
        createPdf,
        uploadBlobServer,
        downloadStudentFile,
        listHistoryBlobs,
        getMyClasses,
        getHistoryPdf,
        changeStaffType,
        getProtectedToken,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
